
.str-chat, .str-chat__container {
    height: 100%;
}

.str-chat.messaging.light.str-chat-channel-list.str-chat__channel-list.str-chat__channel-list-react {
    position: initial;
    background: white;
}

.str-chat__container {
    background: white;
}