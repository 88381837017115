.app {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-align: left;
  margin: 0;
  padding: 0;
}
